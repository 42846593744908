
let userInfo = JSON.parse(window.localStorage.getItem('userInfo' || {}))
let token = userInfo.token
let userName = userInfo.user.userName
let userPhone = userInfo.user.userPhone
//  混入
export const mixins = {
    methods:{
        //  验证参数
        AuthInformation(){
           return{
                userName,userPhone
           }
        },
        //  token
        AuthHeaders(){
            return{
                token
            }
        }
    },
    computed:{
        //  图片
        uploadImageUrl(){
            return '/offerSystem/home/file/uploadPhoto'
        },
        //  图片
        AppUploadImageUrl(){
            return '/offerSystem/app/slideshow/uploadPic'
        },
        //  文件
        uploadFileUrl(){
            return '/offerSystem/home/file/upload'
        },
        uploadCUSTFile(){
            return '/offerSystem/home/file/uploadCUSTFile'
        }
    }
}