export default {
  data() {
    return {
      searchType: 0,
      options: [],
    }
  },
  methods: {
    /* 获取类型选择框的值 */
    getChangeValue(val) {
      console.log(val)
      if (val) {
        this.searchType = val
      } else {
        this.searchType = 0
      }
    },
    async getTypeListHttp() {
      const res = await this.$request({
        url: `/offerSystem/home/typeList/type/findCopyTypeList`,
        method: 'get',
      })
      console.log(res)
      this.options = res.result
    },
  },
  created() {
    this.getTypeListHttp()
  },
}
