<template>
  <div>
    <el-card class="box-card">
      <div class="top">
        <ul>
          <li>
            <el-input
              style="width: 250px"
              placeholder="请输入内容1"
              clearable
              v-model="search"
            >
            </el-input>
            <!-- <type-select @value="getChangeValue"></type-select> -->
            <el-button
              style="margin-left: 10px"
              icon="el-icon-search"
              type="primary"
              @click="serarchBtn"
              >搜索标题</el-button
            >
          </li>
          <li>
            <el-button
              type="primary"
              icon="el-icon-circle-plus"
              @click="addDrawings('add')"
              >添加</el-button
            >
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <el-table
        v-loading="loading"
        :data="drawingsList"
        style="width: 100%"
        border
      >
        <el-table-column prop="imagePageTittle" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="imagePageContent" label="内容" width="180">
        </el-table-column>
        <el-table-column prop="imagePageFileName" label="文件名称">
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="preview(scope.row)"
              >浏览</el-button
            >
            <el-button type="success" size="mini" @click="download(scope.row)"
              >下载</el-button
            >
            <el-button
              type="warning"
              size="mini"
              @click="addDrawings('edit', scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" size="mini" @click="deleteList(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
        :disabled="disableds"
         style="text-align:center; margin:10px 0;"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      title="新建图纸"
      :visible.sync="dialogFormVisible"
      @close="close"
    >
      <el-form :rules="rules" ref="form" :model="form">
        <el-form-item
          label="标题"
          :label-width="formLabelWidth"
          prop="imagePageTittle"
        >
          <el-input
            v-model="form.imagePageTittle"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type" :label-width="formLabelWidth">
          <el-select v-model="form.type" placeholder="请选择类型">
            <el-option
              :label="item.typeCopyName"
              :value="item.id"
              v-for="item in options"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="内容描述"
          :label-width="formLabelWidth"
          prop="imagePageContent"
        >
          <el-input
            type="textarea"
            v-model="form.imagePageContent"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="图纸文件" :label-width="formLabelWidth">
          <el-upload
            class="upload-demo"
            ref="upload"
            drag
            action="/offerSystem/home/file/upload"
            multiple
            :data="AuthInformation()"
            :headers="AuthHeaders()"
            :on-success="afterUpload"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="postDrawing(form)"
          :loading="fileLoading"
          >{{ fileLoading == false ? '提交' : '文件上传中' }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mixins } from '@/util/mixins.js'
import typeSelectMixin from '@/mixins/typeSelect.js'
import {previewFileHost} from "@/util/config.js"
import './style.less'
export default {
  mixins: [mixins, typeSelectMixin],
  data() {
    return {
      search: '', // 搜索
      dialogFormVisible: false,
      formLabelWidth: '120px',
      onLineAddress: '', //  图纸在线预览路径
      imagePageFileAddress: '', //  图纸文件存储地址
      total: 0, // 分页
      pageNum: 1,
      disableds: false,
      loading: false,
      fileName: '',
      form: {
        imagePageTittle: '', //  标题
        imagePageContent: '', //  内容
        imageUrl: '', //  图片
        type: '',
      },
      rules: {
        imagePageTittle: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 1, message: '至少输入1位', trigger: 'blur' },
        ],
        imagePageContent: [
          { required: true, message: '请输入内容', trigger: 'blur' },
          { min: 1, message: '至少输入1位', trigger: 'blur' },
        ],
        imageUrl: [
          { message: '请上传图纸', trigger: 'change', required: true },
        ],
        type: [{ message: '请选择类型', trigger: 'change', required: true }],
      },
      userInfo: [], //  拿到本地存储里的用户id
      drawingsList: [], //  接口数据
      isLoading: false,
      fileLoading: false,
      imageId: '',
      type: '', // 添加或者修改判断
    }
  },
  methods: {
    addDrawings(target, row) {
      this.dialogFormVisible = true
      this.type = target
      if (target == 'edit') {
        this.form.imagePageTittle = row.imagePageTittle
        this.form.imagePageContent = row.imagePageContent
        this.form.imageUrl = row.onLineAddress
        this.form.type = row.typeCopyId
        this.imageId = row.imagePageId
      } else if (target == 'add') {
        this.form = {
          imagePageTittle: '', //  标题
          imagePageContent: '', //  内容
          imageUrl: '', //  图片
          type: '',
        }
      }
    },
    //  确定发送按钮
    postDrawing() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true
          if (this.type == 'add') {
            this.addList()
          } else {
            this.editList()
            this.form.imageUrl = this.onLineAddress
          }
          this.$refs.upload.clearFiles()
          this.isLoading = false
          setTimeout(() => {
            this.getDrawing()
          }, 500)
          this.dialogFormVisible = false
        } else {
          return false
        }
      })
    },
    //  添加请求
    async addList() {
      await this.$request({
        url: '/offerSystem/home/drawingColorPage/drawing/insertImageMsg',
        method: 'post',
        data: {
          imagePageTittle: this.form.imagePageTittle,
          imagePageContent: this.form.imagePageContent,
          releaseUserId: this.userInfo.userId,
          onLineAddress: this.onLineAddress,
          imagePageFileAddress: this.imagePageFileAddress,
          imagePageFileName: this.fileName,
          typeCopyId: this.form.type,
        },
      })
    },
    //  修改请求
    async editList() {
      await this.$request({
        url: '/offerSystem/home/drawingColorPage/drawing/updateImageMsg',
        method: 'put',
        data: {
          imagePageTittle: this.form.imagePageTittle,
          imagePageContent: this.form.imagePageContent,
          imagePageId: this.imageId,
          onLineAddress: this.form.imageUrl,
          imagePageFileAddress: this.imagePageFileAddress,
          typeCopyId: this.form.type,
        },
      })
    },
    //  获取数据
    getDrawing() {
      this.isLoading = true
      this.disableds = true
      this.$request({
        url: '/offerSystem/home/drawingColorPage/drawing/findImagePageMsgById',
        method: 'get',
        params: {
          userId: this.userInfo.userId,
          pageNum: this.pageNum,
          name: this.search,
          typeId: this.searchType,
        },
      }).then((res) => {
        this.drawingsList = res.result.list
        this.total = res.result.total
        this.isLoading = false
        this.disableds = false
      })
    },
    //  删除
    deleteList(e) {
      this.$request({
        url: `/offerSystem/home/drawingColorPage/drawing/deleteImageMsg/${e.imagePageId}`,
        method: 'delete',
      }).then((res) => {
        this.getDrawing()
        this.$message({
          type: 'success',
          message: '删除成功',
        })
      })
    },
    //  分页
    currentChange(page) {
      this.pageNum = page

      this.getDrawing()
    },
    //  上传图片判断
    beforeAvatarUpload(file) {},
    beforeAvatarUpload() {
      this.fileLoading = true
    },
    afterUpload(res) {
      if (res.code === 202) {
        this.onLineAddress = res.onLineAddress
        this.imagePageFileAddress = res.filePath
        this.fileName = res.fileName
        this.$message({
          type: 'success',
          message: '上传成功',
        })
      } else if (res.code != 202) {
        return this.$message({
          message: '上传失败',
        })
      }
      this.fileLoading = false
    },
    //  浏览
    preview(row) {
      window.open(
       previewFileHost+'/onlinePreview?url=' +
          encodeURIComponent(row.onLineAddress)
      )
    },
    //  下载
    download(row) {
      window.open(row.onLineAddress)
    },
    //  搜索
    serarchBtn() {
      this.getDrawing()
    },
    //  弹框关闭
    close() {
      this.fileLoading = false
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
  },
  created() {
    this.userInfo = JSON.parse(
      window.localStorage.getItem('userInfo') || '{}'
    )
    this.getDrawing()
  },
}
</script>